import React, {useState, useContext, useEffect} from 'react';
import {Input, Button, message} from 'antd';
import styled from 'styled-components';
import {Context} from '../../AppContext';
import {RowText, Row} from '../../Widgets';
import Image from '../Image';
import {ErrCoupon, errorHandler} from '../../errors';

export default function CouponBox({setParams, resetTrigger}) {
  const app = useContext(Context);
  const [coupon, setCoupon] = useState();
  const [couponInfo, setCouponInfo] = useState(null);

  const getCoupon = async (code) => {
    try {
      let resp = await app.actions.getCoupon(code);
      setCouponInfo(resp);
      return resp;
    } catch (err) {}
  };

  const apply = async (code) => {
    app.actions.setLoading(true);
    let _coupon = await getCoupon(code);
    try {
      await app.actions.calcOrder({coupon: code});
      message.success('此折扣代碼可使用');
      setCoupon(null);
      setParams({coupon: code});
    } catch (err) {
      let re = /Object with code\=\w+ does not exist\./g;
      if (err.coupon && err.coupon.length > 0 && re.test(err.coupon[0])) {
        err = new ErrCoupon('此折扣代碼不存在');
      }
      if (err.error === 'coupon_expired') {
        err = new ErrCoupon('此折扣代碼過期');
      }
      if (err.error === 'coupon_usage_limit') {
        err = new ErrCoupon('此折扣代碼已兌換完畢');
      }
      if (err.error === 'coupon_user_usage_limit') {
        err = new ErrCoupon('您的折扣代碼已使用達上限');
      }
      if (err.error === 'order_coupon_error') {
        err = new ErrCoupon(
          `訂單金額小於此折扣代碼最低門檻 ${_coupon?.threshold} 元`,
        );
      }
      if (err.error === 'coupon_user_group_error') {
        err = new ErrCoupon(`無法使用此折扣代碼`);
      }
      errorHandler(err);
      reset();
    }
    app.actions.setLoading(false);
  };

  const reset = async () => {
    app.actions.setLoading(true);
    try {
      setParams({coupon: ''});
      setCoupon('');
      setCouponInfo(null);
      await app.actions.calcOrder({coupon: ''});
    } catch (err) {}
    app.actions.setLoading(false);
  };

  useEffect(() => {
    if (couponInfo) {
      setParams({coupon: ''});
      setCouponInfo(null);
    }
  }, [resetTrigger]);

  return (
    <StyledCouponBox>
      <Row style={{width: '100%', flexWrap: 'nowrap'}}>
        <Input value={coupon} onChange={(e) => setCoupon(e.target.value)} />
        <Button onClick={() => apply(coupon)}>使用折扣碼</Button>
      </Row>
      <StyledP>＊折價券最高折價金額，依照各活動說明為準。</StyledP>
      {couponInfo && (
        <CouponTicket>
          <div>
            <Image
              src={`/images/coupon.svg`}
              alt="coupon tag"
              style={{transform: 'scaleX(-1)'}}
            />
          </div>
          <div>
            <RowText
              size="xs"
              color="#fff"
              value={couponInfo.code}
              containerStyle={{marginBottom: 0}}
              noColon
            />
            <RowText
              size="sm"
              color="#fff"
              weight="600"
              value={couponInfo.name}
              containerStyle={{marginBottom: 0}}
              noColon
            />
          </div>
          <div>
            <RowText
              size="xl"
              color="#fff"
              weight="600"
              value={`-${couponInfo.discount}`}
              containerStyle={{marginBottom: 0}}
              noColon
            />
          </div>
          <div onClick={() => reset()}>
            <Image src={`/images/circle-close.svg`} alt="close btn" />
          </div>
        </CouponTicket>
      )}
    </StyledCouponBox>
  );
}

const StyledCouponBox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  flex-wrap: wrap;
  input {
    flex: auto;
    margin-right: 8px;
    height: 40px;
    font-size: 14px;
    color: #9b9b9b;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  button {
    flex: 0 0 95px;
    width: 95px;
    height: 40px;
    line-height: 40px;
    border-radius: 5px;
    background-color: #505050;
    color: #fafafa;
    font-size: 14px;
    font-weight: 500;
    padding: 0;
  }
`;

const CouponTicket = styled.div`
  display: flex;
  align-items: center;
  background-color: #d0a67d;
  flex: 0 0 100%;
  max-width: 100%;
  padding: 8px 12px;
  border-radius: 6px;
  & > div {
    &:nth-child(1) {
      margin-right: 12px;
    }

    &:nth-child(3) {
      display: flex;
      flex: 1;
      justify-content: end;
      padding: 0 10px;
    }

    &:last-child {
      cursor: pointer;
      margin-left: 8px;
      line-height: 18px;
    }
  }
`;

const StyledP = styled.p`
  font-size: ${(props) => (props.medium ? '14px' : '12px')};
  color: #505050;
`;

import React from 'react';
import {Modal, Button} from 'antd';
import styled from 'styled-components';

const appConfig = require('../data.json');
const htmls = require('../content-html.json');

export default function CheckoutAgreement({onConfirm, currentAgreementModal}) {
  const renderContent = () => {
    switch (currentAgreementModal) {
      case 'terms':
        return <div dangerouslySetInnerHTML={{__html: htmls.terms}}></div>;
      case 'print_notice':
        return (
          <div dangerouslySetInnerHTML={{__html: htmls.print_notice}}></div>
        );
      default:
        return null;
    }
  };

  const getModalTitle = () => {
    switch (currentAgreementModal) {
      case 'terms':
        return '服務條款聲明';
      case 'print_notice':
        return '印刷須知';
      default:
        return null;
    }
  };

  return (
    <StyledModal
      title={getModalTitle()}
      visible={currentAgreementModal}
      footer={<Footer onConfirm={onConfirm} />}
      width="680px"
      destroyOnClose>
      {renderContent()}
    </StyledModal>
  );
}

const Footer = ({onConfirm}) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end',
      }}>
      <StyledButton className="confirm-button" onClick={onConfirm}>
        確認
      </StyledButton>
    </div>
  );
};

const StyledModal = styled(Modal)`
  .ant-modal-close {
    display: none;
  }
  .ant-modal-content {
    border-radius: 10px;
  }
  .ant-modal-header {
    padding: 12px 30px;
    background-color: #fafafa;
    border-radius: 10px 10px 0 0;
    .ant-modal-title {
      font-size: 16px;
      font-weight: 500;
      color: #5a5a5a;
    }
  }
  .ant-modal-body {
    padding: 20px 30px 0;
    height: 65vh;
    overflow-y: auto;
  }
  .ant-modal-footer {
    padding: 14px 0;
    margin: 0 30px;
  }
`;

const StyledButton = styled(Button)`
  width: 64px;
  height: 40px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  color: #fafafa;
  padding: 0;
  border: 1px solid transparent;
  background-color: #ccc;
  &.confirm-button {
    background-color: ${appConfig.colors.main};
    &:hover {
      color: #fafafa;
      opacity: 0.75;
    }
  }
`;

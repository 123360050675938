import React, {Fragment, useEffect} from 'react';
import {Button, Input, Radio, Select, Form} from 'antd';
import styled from 'styled-components';
import {TWO_COPIES_TYPES, LOVECODE_OPTIONS} from '../../dictionary';

const appConfig = require('../../data.json');

export default function InvoiceInfo({config: _config, setConfig}) {
  const invoiceConfig = _config?.invoiceConfig;

  const renderCocntent = () => {
    switch (invoiceConfig.invoice_type) {
      case 'two_copies':
        return (
          <TwoPartInvoice setConfig={setConfig} invoiceConfig={invoiceConfig} />
        );
      case 'three_copies':
        return <ThreePartInvoice />;
      case 'donate':
        return (
          <DonateInvoice invoiceConfig={invoiceConfig} setConfig={setConfig} />
        );
      default:
        return null;
    }
  };

  return (
    <Fragment>
      {renderCocntent()}
      <StyledFormItem label="發票收取Email" name="email">
        <Input placeholder="Email" />
      </StyledFormItem>
      <StyledP>
        根據財政部令財台稅字第1070001765號訂之「電子發票實施作業要點」，開立之統一發票將不主動郵寄，將會以
        Email
        寄送方式提供，並將發票檔上傳至政府「電子發票整合服務平台」。提醒您！若填寫免費信箱
        (如Yahoo、 Gmail) 可能被歸類於垃圾信件中造成收不到信的問題。
      </StyledP>
    </Fragment>
  );
}

const TwoPartInvoice = ({setConfig, invoiceConfig}) => {
  return (
    <Fragment>
      <StyledFormItem label="發票類型" name="invoice_subtype">
        <StyledSelect
          placeholder="選擇發票開立方式"
          onChange={(value) => {
            setConfig('invoice', {invoice_subtype: value});
          }}
          options={Object.values(TWO_COPIES_TYPES).map((type) => ({
            value: type.value,
            label: type.label,
          }))}
        />
      </StyledFormItem>
      {invoiceConfig.invoice_subtype === 'citizen_personal_certificate' ? (
        <StyledFormItem
          label="條碼編號"
          name="citizen_personal_certificate_code">
          <Input placeholder="請輸入自然人憑證條碼16碼" />
        </StyledFormItem>
      ) : invoiceConfig.invoice_subtype === 'mobile_vehicle' ? (
        <StyledFormItem label="條碼編號" name="mobile_vehicle_code">
          <Input placeholder="請輸入手機載具條碼8碼" />
        </StyledFormItem>
      ) : null}
    </Fragment>
  );
};

const ThreePartInvoice = () => {
  return (
    <Fragment>
      <StyledFormItem label="統一編號" name="gui_number">
        <Input placeholder="統一編號" />
      </StyledFormItem>
      <StyledFormItem label="公司抬頭" name="company_title">
        <Input placeholder="公司抬頭" />
      </StyledFormItem>
      <StyledButton
        target="_blank"
        href="https://www.etax.nat.gov.tw/etwmain/etw113w1/ban/query"
        style={{marginBottom: '16px'}}>
        查詢公司抬頭
      </StyledButton>
    </Fragment>
  );
};

const DonateInvoice = ({invoiceConfig, setConfig}) => {
  return (
    <Fragment>
      <StyledFormItem label="愛心碼" name="love_code">
        <StyledSelect
          placeholder="選擇發票開立方式"
          defaultValue={invoiceConfig.love_code}
          onChange={(value) => {
            setConfig('invoice', {love_code: value});
          }}
          options={LOVECODE_OPTIONS.map((option) => ({
            value: option.value,
            label: option.label,
          }))}
        />
      </StyledFormItem>
    </Fragment>
  );
};

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 14px;
  .ant-form-item-label > label {
    color: #505050;
  }
  input {
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 2px;
    padding: 10px 12px;
    font-size: 14px;
    color: #505050;
  }
  .ant-radio-wrapper {
    font-size: 14px;
    color: #505050;
  }

  .ant-form-item {
    margin-bottom: 14px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .ant-select.ant-select-in-form-item {
    margin: 0;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 40px;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-selection-placeholder {
    display: inline-flex;
    align-items: center;
  }
`;

const StyledP = styled.p`
  font-size: 12px;
  line-height: 1.75;
  color: #505050;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${appConfig.colors.main};
  color: #fafafa;
  font-size: 14px;
  font-weight: 500;
  width: 148px;
  height: 40px;
  border-radius: 5px;
  padding: 0;
`;

const StyledSelect = styled(Select)`
  font-size: 14px;
  color: #505050;
  width: 200px;
  height: 40px;
  border-radius: 5px;
  margin-bottom: 12px;
  .ant-select-selector,
  .ant-select {
    border: none;
  }
`;

import React, {
  useCallback,
  useEffect,
  useContext,
  useState,
  useMemo,
} from 'react';
import {Context} from '../../AppContext';
import {ErrorOutline} from '@styled-icons/material-outlined';
import styled from 'styled-components';
import {Text} from '../../Widgets';

const appConfig = require('../../data.json');

export default function PromotionSection({goToPrevStep}) {
  const [promos, setPromos] = useState({});
  const app = useContext(Context);
  const {cart} = app.state;

  const getPromos = useCallback(async () => {
    if (!cart) return;
    try {
      const resp = await app.actions.getPromotions();
      setPromos(resp);
    } catch (err) {
      console.error('Failed to fetch promotions:', err);
    }
  }, [cart, app.actions]);

  useEffect(() => {
    getPromos();
  }, [getPromos]);

  const result = useMemo(() => {
    let feePromotionResult = [];
    let thresholdPromotionResult = [];

    const feePromotion = promos['fee_promotion'];
    if (feePromotion) {
      const exclude = cart.items.some((item) =>
        feePromotion.exclude_product.includes(item.name),
      );
      feePromotionResult.push(
        exclude
          ? `您的購物車包含無免運活動之商品，不符合${feePromotion.name}條件！`
          : `商品差 ${promos.fee_promotion_diff} 元，免運`,
      );
    }

    for (let i = 1; ; i++) {
      const thresholdPromo = promos[`threshold_promotion_${i}`];
      if (!thresholdPromo) break;
      thresholdPromotionResult.push(
        `商品差 ${promos[`threshold_promotion_diff_${i}`]} 元，折抵 ${
          thresholdPromo.value
        } 元`,
      );
    }

    return {
      feePromotionResult,
      thresholdPromotionResult,
    };
  }, [promos, cart]);

  const KeepShopping = () => {
    return (
      <Text
        size="ms"
        color={appConfig.colors.main}
        css={{cursor: 'pointer', textDecoration: 'underline'}}
        onClick={goToPrevStep}>
        前往選購Go
      </Text>
    );
  };

  const hasPromotion =
    result.feePromotionResult.length > 0 ||
    result.thresholdPromotionResult.length > 0;

  return hasPromotion ? (
    <Wrap>
      <Text size="md" style={{color: '#505050', marginBottom: '12px'}}>
        優惠說明
      </Text>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
          gap: '8px',
        }}>
        {result.feePromotionResult.length > 0 &&
          result.feePromotionResult.map((text, i) => (
            <PromotionTextContainer key={i}>
              <ErrorOutline size={20} color="#505050" />
              <Text size="ms" color="#505050" weight="500">
                未達免運標準
              </Text>
              <Text size="ms" color="#505050">
                {text}
              </Text>
              <KeepShopping />
            </PromotionTextContainer>
          ))}
        {result.thresholdPromotionResult.length > 0 &&
          result.thresholdPromotionResult.map((text, i) => (
            <PromotionTextContainer key={i}>
              <ErrorOutline size={20} color="#505050" />
              <Text size="ms" color="#505050" weight="500">
                未達折扣標準
              </Text>
              <Text size="ms" color="#505050">
                {text}
              </Text>
              <KeepShopping />
            </PromotionTextContainer>
          ))}
      </div>
    </Wrap>
  ) : null;
}

const Wrap = styled.div`
  display: block;
  background-color: #fafafa;
  padding: 16px 26px;
  border-radius: 10px;
  margin-bottom: 14px;
`;

const PromotionTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  width: 100%;
  background-color: #f2f2f2;
  padding: 10px;
  border-radius: 8px;
`;

import React, {Fragment, useEffect, useState} from 'react';
import {Form, Input, Radio, Checkbox} from 'antd';
import AddressField from './AddressField';
import styled from 'styled-components';

export default function ShippingInfo({config: _config}) {
  const config = _config.deliveryConfig;
  const form = Form.useFormInstance();
  const [sameAsUser, setSameAsUser] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      sender_name: config.sender_name,
      sender_phone: config.sender_phone,
      sender_zip: config.sender_zip,
      sender_address: config.sender_address,
      sender_city: config.sender_city,
      sender_district: config.sender_district,
      receiver_name: config.receiver_name,
      receiver_phone: config.receiver_phone,
      receiver_address: config.receiver_address,
      receiver_city: config.receiver_city,
      receiver_district: config.receiver_district,
      zip_code: config.zip,
      note: config.note,
    });
  }, [form, config]);

  useEffect(() => {
    if (sameAsUser) {
      form.setFieldsValue({
        ...config,
        receiver_name: config.receiver_name,
        receiver_phone: config.receiver_phone,
        receiver_address: config.receiver_address,
        receiver_city: config.receiver_city,
        receiver_district: config.receiver_district,
        zip_code: config.zip,
      });
    }
  }, [sameAsUser]);

  return (
    <Fragment>
      {config.is_delivery_private && (
        <>
          <StyledFormItem label="寄件人姓名" name="sender_name">
            <Input placeholder="寄件人姓名" />
          </StyledFormItem>
          <StyledFormItem label="寄件人手機號碼" name="sender_phone">
            <Input placeholder="0900-111-222" />
          </StyledFormItem>
          <StyledFormItem label="寄件人地址">
            <AddressField
              zip_code={config.sender_zip}
              city={config.sender_city}
              prefix="sender_"
            />
          </StyledFormItem>
        </>
      )}
      <StyledFormItem name="same_as_user" valuePropName="checked">
        <Checkbox
          onChange={(e) => {
            setSameAsUser(e.target.checked);
          }}>
          同會員資料
        </Checkbox>
      </StyledFormItem>
      <StyledFormItem label="收件人姓名" name="receiver_name">
        <Input placeholder="收件人姓名" />
      </StyledFormItem>
      <StyledFormItem label="手機號碼" name="receiver_phone">
        <Input placeholder="0900-111-222" />
      </StyledFormItem>
      <StyledFormItem label="收件地址">
        <AddressField
          zip_code={config.zip}
          city={config.sender_city}
          prefix="receiver_"
        />
      </StyledFormItem>
      <StyledFormItem label="收件備註" name="delivery_note">
        <Input placeholder="收件備註" />
      </StyledFormItem>
      <StyledFormItem name="info" valuePropName="checked">
        <Radio>儲存成為常用收件資訊</Radio>
      </StyledFormItem>
      {/* <Button onClick={handleOnSubmit}>送出</Button> */}
    </Fragment>
  );
}

const StyledFormItem = styled(Form.Item)`
  .ant-form-item-label > label {
    color: #505050;
  }
  input {
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 2px;
    padding: 10px 12px;
    font-size: 14px;
    color: #505050;
  }
  .ant-radio-wrapper {
    font-size: 14px;
    color: #505050;
  }

  .ant-form-item {
    margin-bottom: 14px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

import React from 'react';
import styled from 'styled-components';
import {Text} from '../../Widgets';

export default function ShoppingGuidelinesSection() {
  return (
    <Wrap>
      <Text
        size="md"
        color="#505050"
        weight="500"
        style={{marginBottom: '10px'}}>
        請詳閱購物須知
      </Text>
      <Text size="sm" color="#505050">
        ※
        【工作天計算】訂單成立後上傳檔案且付款完成，審稿階段檔案合格後，隔日起算商品賣場製作時間（工作天不含假日及運送時間）;
        同筆訂單實際工作天依較長工作天商品為主作計算，請注意商品製作時間及預計出貨日，如果有指定交期或急件需求，請加line私訊小編討論急件處理方案。
      </Text>
      <Text size="sm" color="#505050">
        ※
        【訂單狀態】下單商品若是需要審稿之訂單，訂單進到審稿階段後，需由客戶自行注意官網訂單狀態更新及追蹤進度。若訂單稿件審稿不合格，將發送Email通知及調整訂單狀態，不再另行主動訊息及電聯通知。
      </Text>
      <Text size="sm" color="#505050">
        ※
        【訂單發票】訂單發票一律在已出貨且訂單完成後，開立電子發票寄送發票證明聯到會員Email。
      </Text>
      <Text size="sm" color="#505050">
        ※ 【訂單估價單】如需訂單估價單申請請款，可於訂單成立後下載估價單PDF檔。
      </Text>
    </Wrap>
  );
}

const Wrap = styled.div`
  display: block;
  background-color: #fafafa;
  padding: 16px 26px;
  border-radius: 10px;
  font-size: 14px;
  color: #505050;
  line-height: 1.71;
`;

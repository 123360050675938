import React, {useContext, useState} from 'react';
import {Context} from '../../AppContext';
import {Button} from '../../Widgets';
import {isVIP, isMonthly} from '../../Utils/UserUtil';
import CartItemTable from '../../Components/CartItemTable';
import PromotionSection from './PromotionSection';
import ShoppingGuidelinesSection from './ShoppingGuidelinesSection';
import styled from 'styled-components';
const config = require('../../data.json');

export default function Bag(props) {
  const {goToNextStep, goToPrevStep} = props;
  const app = useContext(Context);
  const {profile, cart} = app.state;

  let creditsNotAllow =
    isVIP(profile) &&
    !isMonthly(profile) &&
    profile.credits < cart.calculations.amount;

  return (
    <div>
      <CartItemTable />

      <PromotionSection goToPrevStep={goToPrevStep} />

      <ShoppingGuidelinesSection />

      {goToNextStep && (
        <ButtonsContainer>
          <StyledButton
            type="default"
            style={{
              color: '#505050',
              backgroundColor: 'white',
            }}
            onClick={goToPrevStep}>
            繼續購物
          </StyledButton>
          <div style={{flex: 1}}></div>
          <StyledButton
            style={{
              color: 'white',
            }}
            onClick={goToNextStep}>
            前往結帳
          </StyledButton>
        </ButtonsContainer>
      )}

      {creditsNotAllow && (
        <div
          style={{
            margin: '20px 0px',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}>
          您的點數餘額不足。
          <a href="/account_manage" style={{color: config.colors.main}}>
            前往儲值
          </a>
        </div>
      )}
    </div>
  );
}

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0px 94px;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    margin: 16px 0 50px;
    button {
      width: 100%;
      margin-bottom: 10px;
    }
  }
`;

const StyledButton = styled(Button)`
  width: 210px;
  height: 45px;
  font-size: 18px;
  font-weight: 500;
  border-radius: 10px;
  letter-spacing: 1.2px;
`;

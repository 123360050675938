import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
  Fragment,
} from 'react';
import {Context} from '../../AppContext';
import EmptyCart from './EmptyCart';
import ContentWrapper from '../../Components/Layout/ContentWrapper';
import Steps from './Steps';
import CartBag from './CartBag';
// import Checkout from './Checkout';
import CheckoutNew from './CheckoutNew';
import UnAuthResult from '../../Components/UnAuthResult';
import {navigate} from 'gatsby';
import PaymentSelect from './PaymentSelect';
import {FixedBar} from '../../Widgets';
import {ErrCoupon, ErrCheckoutForm, errorHandler} from '../../errors';
import {
  CUSTOMER_CONFIGS,
  SHIPPING_CONFIGS,
  INVOICE_CONFIGS,
} from '../../Constants/CartConfigs';
const qs = require('query-string');
const appConfig = require('../../data.json');

export default function CartPage(props) {
  const {location} = props;
  const queryParams = qs.parse(location.search);
  const app = useContext(Context);
  const {cart, profile} = app.state;

  const [step, setStep] = useState(0);

  const [redirecting, setRedirecting] = useState(
    queryParams.step ? true : false,
  );

  const [config, setConfig] = useState({
    userConfig: {
      ...CUSTOMER_CONFIGS,
      ...cart?.config?.userConfig,
    },
    deliveryConfig: {
      ...SHIPPING_CONFIGS,
      ...cart?.config?.deliveryConfig,
    },
    invoiceConfig: {
      ...INVOICE_CONFIGS,
      ...cart?.config?.invoiceConfig,
    },
    extraConfig: {
      use_bonus: true,
    },
  });
  let prevDeliveryType = useRef(appConfig.supportLogistics[0]);
  const prevUseBonus = useRef(false);
  const [params, setParams] = useState({
    payment_type: '',
    payment_subtype: '',
    coupon: '',
    note: '',
    is_share_order_photo: false,
    share_info: '',
  });

  const autofill = useCallback(() => {
    if (cart && profile) {
      const {config: _config} = cart;

      //user
      let userConfig = {
        name: _config.name || profile.name,
        phone: _config.phone || profile.phone,
        email: _config.email || profile.email,
        tel: _config.tel,
        tel_ext: _config.tel_ext,
        zip: _config.zip || profile.zip_code,
        city: _config.city || profile.city,
        district: _config.district || profile.district,
        address: _config.address || profile.address,
      };

      //delivery
      //not fill, default 新竹物流

      //invoice
      let invoiceConfig = config.invoiceConfig;

      //other config
      let extraConfig = {
        use_bonus: _config.use_bonus,
      };

      setConfig({
        userConfig,
        deliveryConfig: config.deliveryConfig,
        invoiceConfig,
        extraConfig,
      });
    }
  }, [cart, profile]);

  // inital autofill
  useEffect(() => {
    if (cart && profile) {
      autofill();
    }
  }, [cart, profile, autofill]);

  const updateConfig = useCallback(
    async (callback = () => {}) => {
      app.actions.setLoading(true);
      const {userConfig, deliveryConfig, invoiceConfig, extraConfig} = config;

      try {
        let resp = await app.actions.editConfig({
          ...userConfig,
          ...extraConfig,
          deliveryConfig,
          invoiceConfig,
        });
        autofill();
        callback();
      } catch (ex) {
        console.log(ex);
        // errorHandler(ex);
      }
      app.actions.setLoading(false);
    },
    [app.actions, autofill, config],
  );

  // delivery_type changed, then update cart.config
  useEffect(() => {
    if (prevDeliveryType.current !== config.deliveryConfig.delivery_type) {
      prevDeliveryType.current = config.deliveryConfig.delivery_type;
      updateConfig();
    }
    if (prevUseBonus.current !== config.extraConfig.use_bonus) {
      prevUseBonus.current = config.extraConfig.use_bonus;
      updateConfig();
    }
  }, [
    config.deliveryConfig.delivery_type,
    config.extraConfig.use_bonus,
    updateConfig,
  ]);

  // 切換步驟時，總是回到最頂端

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }, [step]);

  useEffect(() => {
    // ezship redirect back to cart step 2
    if (redirecting && profile && cart) {
      setConfig((prev) => ({
        ...prev,
        deliveryConfig: {
          ...prev.deliveryConfig,
          delivery_type: queryParams.store_type
            ? queryParams.store_type === 'FAMI'
              ? 'xdelivery'
              : 'payuni'
            : 'ezship',
          st_state: queryParams.store_type || queryParams.st_state,
          st_code: queryParams.st_code,
          rstore_name: queryParams.rstore_name,
          rstore_tel: queryParams.rstore_tel,
          rstore_addr: queryParams.rstore_addr,
        },
      }));

      setStep(queryParams.step - 1);
      setRedirecting(false);
    }
  }, [redirecting, profile, cart]);

  async function createOrder() {
    try {
      if (params.payment_type === 'period') {
        if (window.confirm('請選擇使用期限足夠的信用卡')) {
          let {url} = await app.actions.getPeriodCheckoutUrl();

          if (url) {
            window.location.href = url;
          }
        }
      } else {
        const order = await app.actions.checkout(params);

        // Tracking.checkout(step, cart, params, profile?.id);
        navigate(`/order?id=${order.id}`);
        app.actions.getCart(); // refresh cart
      }
    } catch (err) {
      if (err.error === 'user_is_frozen') {
        err = new ErrCheckoutForm(
          '月結帳號已凍結，您的月結單繳費期限已到期，帳號已凍結限制下單，為避免因凍結會員下單權限而損失您的權益，請儘速前往繳費！謝謝',
        );
      }
      let re = /Object with code\=\w+ does not exist\./g;
      if (err.coupon && err.coupon.length > 0 && re.test(err.coupon[0])) {
        err = new ErrCoupon('此折扣代碼不存在');
      }

      if (err.error === 'coupon_expired') {
        err = new ErrCoupon('此折扣代碼過期');
      }

      if (err.error === 'coupon_usage_limit') {
        err = new ErrCoupon('此折扣代碼已兌換完畢');
      }

      if (err.error === 'coupon_user_usage_limit') {
        err = new ErrCoupon('您的折扣代碼已使用達上限');
      }

      if (err.error === 'order_coupon_error') {
        err = new ErrCoupon(`訂單金額小於此折扣代碼最低門檻`);
      }

      if (err.error === 'coupon_user_group_error') {
        err = new ErrCoupon(`無法使用此折扣代碼`);
      }
      if (err.error === 'user_monthly_quota_over') {
        re = /monthly_quota\:(\d+).+order_amount:(\d+).+cart_amount:(\d+)/;
        let match = typeof err.detail === 'string' ? err.detail.match(re) : [];
        let rest = parseInt(match?.[1]) - parseInt(match?.[2]);
        err = new ErrCoupon(
          `當月月結額度不足！已超過當月剩餘月結額度：＄${rest} / ＄${match?.[1]}，請聯絡客服人員`,
        );
      }
      if (
        err.error === 'config_error' &&
        Array.isArray(err.detail) &&
        err.detail.length > 0
      ) {
        if (err.detail[0].indexOf('is required') > 0) {
          err = new ErrCheckoutForm('有欄位必填，尚未填寫');
        } else {
          err = new ErrCheckoutForm('有欄位格式錯誤');
        }
      }
      throw err;
    }
  }

  if (!profile) {
    return <UnAuthResult />;
  }

  if (!cart || redirecting) {
    return null;
  }
  if (cart.items.length === 0) {
    return <EmptyCart />;
  }

  function changeStep(next = true) {
    setStep((prev) => prev + (next ? 1 : -1));
  }

  const stepMessages = [
    '購物車提醒：下單前請注意商品選擇規格正確性，印製檔案於 Step3 訂單成立後可上傳稿件。',
    '購物車提醒：本網站採先收款後製作，付款成功後才算正式成立訂單。請注意！收件人姓名需與證件姓名相同。紅利金若折抵後無論任何原因取消訂單，將無法補回紅利，請謹慎留意。',
  ];

  return (
    <Fragment>
      <FixedBar>{stepMessages[step]}</FixedBar>
      <ContentWrapper bg="#ededed" maxWidth="1080">
        <Steps step={step} />
        {step === 0 && (
          <CartBag
            goToPrevStep={() => navigate('/products')}
            goToNextStep={async () => {
              await updateConfig(changeStep);
            }}
          />
        )}

        {/* {step === 1 && (
          <Checkout
            goToPrevStep={() => changeStep(false)}
            goToNextStep={() => changeStep()} //
            config={config}
            setConfig={(type, config) => {
              setConfig((prev) => {
                return {...prev, [`${type}Config`]: config};
              });
            }}
            updateConfig={updateConfig}
            params={params}
            setParams={(obj) => setParams((prev) => ({...prev, ...obj}))}
          />
        )} */}

        {step === 1 && (
          <CheckoutNew
            config={config}
            setConfig={(type, config) => {
              setConfig((prev) => {
                return {
                  ...prev,
                  [`${type}Config`]: {
                    ...prev[`${type}Config`],
                    ...config,
                  },
                };
              });
            }}
            updateConfig={updateConfig}
            params={params}
            setParams={(obj) => setParams((prev) => ({...prev, ...obj}))}
            createOrder={() => createOrder()}
            queryParams={queryParams}
          />
        )}

        {/* {step === 2 && (
          <PaymentSelect
            goToPrevStep={() => changeStep(false)}
            goToNextStep={() => createOrder()} //
            params={params}
            setParams={(obj) => setParams((prev) => ({...prev, ...obj}))}
            config={config}
            setConfig={(type, config) => {
              setConfig((prev) => ({
                ...prev,
                [`${type}Config`]: config,
              }));
            }}
          />
        )} */}
      </ContentWrapper>
    </Fragment>
  );
}

import React, {useState} from 'react';
import {Radio} from 'antd';
import styled from 'styled-components';
import usePaymentTypeOptions from '../../hooks/use-payment-type-options';
import {PAYMENT_TYPES} from '../../dictionary';
const appConfig = require('../../data.json');

export default function PaymentMethods({setParams}) {
  const [type, setType] = useState(null);
  const {options} = usePaymentTypeOptions({type});

  const handleRadioOnChange = (value) => {
    setType(value);
    const mapping = {
      atm: {payment_type: 'payuni', payment_subtype: value},
      credit: {payment_type: 'payuni', payment_subtype: value},
      cvs: {payment_type: 'payuni', payment_subtype: value},
      line_pay: {payment_type: 'payuni', payment_subtype: value},
      credits: {payment_type: value, payment_subtype: 'default'},
      monthly: {payment_type: value, payment_subtype: 'default'},
      offline: {payment_type: value, payment_subtype: 'default'},
      period: {payment_type: 'period', payment_subtype: 'default'},
    };
    setParams(mapping[value]);
  };

  return (
    <Radio.Group
      onChange={(e) => handleRadioOnChange(e.target.value)}
      value={type}
      style={{width: '100%'}}>
      <ul style={{listStyle: 'none'}}>
        {options.map((item, idx) => (
          <PaymentMethodItem
            label={item.label}
            value={item.value}
            key={item.label}
            isSelected={type === item.value}
          />
        ))}
      </ul>
    </Radio.Group>
  );
}

const PaymentMethodItem = ({label, value, isSelected, disabled}) => {
  return (
    <StyledLi isSelected={isSelected} disabled={disabled}>
      <Radio value={value}>{label}</Radio>
      {isSelected && (
        <div>
          {PAYMENT_TYPES.find((type) => type.value === value)?.intro || ''}
        </div>
      )}
    </StyledLi>
  );
};

const Tips = styled.p`
  font-size: 12px;
  line-height: 1.75;
  color: #505050;
  margin-bottom: 0;
`;

const StyledLi = styled.li`
  padding: 14px 18px;
  border-radius: 10px;
  border: solid 1px
    ${(props) => (props.isSelected ? appConfig.colors.main : '#ccc')};
  font-size: 14px;
  font-weight: 500;
  color: #505050;
  margin-bottom: 12px;
  transition: 0.3s all;
  div {
    font-weight: 400;
    padding: 8px 0 0 24px;
  }
  span {
    color: ${(props) => (props.isSelected ? appConfig.colors.main : '#505050')};
  }
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

import React, {useState, useContext} from 'react';
import styled from 'styled-components';
import {Modal, Button, Radio, Checkbox} from 'antd';
import {Context} from '../AppContext';

const appConfig = require('../data.json');

export default function OrderReminder({visible, onConfirm, onCancel}) {
  const app = useContext(Context);
  const {agreement} = app.state;
  const [isAgreed, setIsAgreed] = useState(false);
  return (
    <StyledModal
      title="成立訂單前注意事項"
      visible={visible}
      onCancel={onCancel}
      footer={
        <Footer
          onConfirm={onConfirm}
          isAgreed={isAgreed}
          setIsAgreed={setIsAgreed}
        />
      }
      width="950px">
      <div dangerouslySetInnerHTML={{__html: agreement.html}}></div>
    </StyledModal>
  );
}

const Footer = ({onConfirm, isAgreed, setIsAgreed}) => {
  const handleChange = () => {
    setIsAgreed(!isAgreed);
  };

  return (
    <div>
      <Checkbox
        style={{width: '100%', marginBottom: '22px'}}
        onClick={handleChange}
        checked={isAgreed}>
        我已閱讀並接受本網站
        <span style={{color: appConfig.colors.main}}>
          條款聲明、印刷須知、常見問題
        </span>
        所有注意事項及條款須知。
      </Checkbox>

      <StyledButton
        disabled={!isAgreed}
        className="confirm-button"
        onClick={onConfirm}>
        我已了解並同意注意事項！
      </StyledButton>
    </div>
  );
};

const StyledModal = styled(Modal)`
  .ant-modal-close {
    display: none;
  }
  .ant-modal-content {
    border-radius: 10px;
  }
  .ant-modal-header {
    padding: 8px 0;
    background-color: #fff6c6;
    border-radius: 10px 10px 0 0;
    .ant-modal-title {
      font-size: 20px;
      font-weight: 600;
      color: #505050;
      text-align: center;
      letter-spacing: 6px;
    }
  }
  .ant-modal-body {
    padding: 26px 32px 24px;
  }
  .ant-modal-footer {
    padding: 20px 32px 24px;
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 500;
  color: #505050;
  padding: 10px 0;
  background-color: #ffe664;
  border: none;
`;

import React, {useState, useEffect, useContext, useCallback} from 'react';
import {Modal, Button, Form} from 'antd';
import styled from 'styled-components';
import CustomerInfo from '../Components/Checkout/CustomerInfo';
import ShippingInfo from '../Components/Checkout/ShippingInfo';
import InvoiceInfo from '../Components/Checkout/InvoiceInfo';
import {Context} from '../AppContext';
const appConfig = require('../data.json');
export default function CheckoutDetail({
  config,
  setConfig,
  onCancel,
  currentEditModal,
  logistics,
}) {
  const app = useContext(Context);
  const [form] = Form.useForm();
  const [prevConfig, setPrevConfig] = useState(null);

  useEffect(() => {
    if (currentEditModal) {
      // 記憶修改前的狀態
      setPrevConfig(config);
    }
  }, [currentEditModal]);

  const renderContent = () => {
    switch (currentEditModal) {
      case 'customer':
        return <CustomerInfo config={config} setConfig={setConfig} />;
      case 'invoice':
        return <InvoiceInfo config={config} setConfig={setConfig} />;
      case 'shipping':
        return <ShippingInfo config={config} logistics={logistics} />;
      default:
        return null;
    }
  };

  const getModalTitle = () => {
    switch (currentEditModal) {
      case 'customer':
        return '顧客資訊';
      case 'invoice':
        return '發票資訊';
      case 'shipping':
        return '收件資訊';
      default:
        return null;
    }
  };

  const handleCancel = () => {
    onCancel();
    if (prevConfig) {
      switch (currentEditModal) {
        case 'invoice':
          setConfig('invoice', {
            ...prevConfig,
            invoice_type: config.invoiceConfig.invoice_type,
          });
          break;
        case 'customer':
          setConfig('user', {...prevConfig});
          break;
        case 'shipping':
          setConfig('delivery', {...prevConfig});
          break;
        default:
          break;
      }
    }
  };

  const handleOnConfirm = () => {
    const configs = form.getFieldsValue();
    switch (currentEditModal) {
      case 'invoice':
        setConfig('invoice', configs);
        break;
      case 'customer':
        setConfig('user', configs);
        break;
      case 'shipping':
        if (configs.info) {
          app.actions.addLogisticsAddress(configs);
        }
        setConfig('delivery', configs);
        break;
      default:
        break;
    }
    onCancel();
  };

  return (
    <StyledModal
      title={getModalTitle()}
      visible={currentEditModal}
      onCancel={handleCancel}
      footer={<Footer onCancel={handleCancel} onConfirm={handleOnConfirm} />}
      width="680px"
      destroyOnClose>
      <Form layout="vertical" form={form}>
        {renderContent()}
      </Form>
    </StyledModal>
  );
}

const Footer = ({onConfirm, onCancel}) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
      <StyledButton onClick={onCancel}>取消</StyledButton>
      <StyledButton className="confirm-button" onClick={onConfirm}>
        確認
      </StyledButton>
    </div>
  );
};

const StyledModal = styled(Modal)`
  .ant-modal-close {
    display: none;
  }
  .ant-modal-content {
    border-radius: 10px;
  }
  .ant-modal-header {
    padding: 12px 30px;
    background-color: #fafafa;
    border-radius: 10px 10px 0 0;
    .ant-modal-title {
      font-size: 16px;
      font-weight: 500;
      color: #5a5a5a;
    }
  }
  .ant-modal-body {
    padding: 20px 30px 0;
  }
  .ant-modal-footer {
    padding: 14px 0;
    margin: 0 30px;
  }
`;

const StyledButton = styled(Button)`
  width: 64px;
  height: 40px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  color: #fafafa;
  padding: 0;
  border: 1px solid transparent;
  background-color: #ccc;
  &.confirm-button {
    background-color: ${appConfig.colors.main};
    &:hover {
      color: #fafafa;
      opacity: 0.75;
    }
  }
`;
